import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import allReducers from './allReducers';

import uploadSaga from './Upload/uploadSaga';
import userUnlockSaga from './Upload/userUnlockSaga';
import uiSaga from './UI/uiSaga';
import activeMeetingsSaga from './Meeting/activeMeetingsSaga';
import meetingPopupSaga from './Meeting/meetingPopupSaga';
import { websocketMiddleware } from 'store/websocket-middleware';
import { meetingMiddleware } from 'store/Meeting/meeting-middleware';
import { indexedDbCommentsMiddleware } from 'store/indexeddb-comments-middleware';
import { indexedDbNotificationsMiddleware } from 'store/indexeddb-notifications-middleware';
import { indexedDbLinkedItemsMiddleware } from 'store/indexeddb-linkedItems-middleware';
import { indexedDbResourcesMiddleware } from 'store/indexeddb-resource-middleware';
import desktopAppReviewSaga from 'store/UI/desktopAppReviewSaga';
import { analyticsMiddleware } from 'store/analytics-middlesware';

const persistConfig = {
  key: 'root5.8',
  storage,
  whitelist: ['chatMessage'],
  // whitelist: ['repositories', 'spaces', 'adapters', 'load', 'locale', 'user'],
};

const appReducer = combineReducers({
  ...allReducers,
  routing: routerReducer,
});

const persistedReducer = persistReducer(persistConfig, appReducer);

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// then run the saga

export default function (browserHistory) {
  const composeEnhancers = composeWithDevTools({ maxAge: 500 });
  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        thunk,
        meetingMiddleware,
        websocketMiddleware,
        analyticsMiddleware,
        indexedDbCommentsMiddleware,
        indexedDbNotificationsMiddleware,
        indexedDbLinkedItemsMiddleware,
        indexedDbResourcesMiddleware,
        sagaMiddleware,
        routerMiddleware(browserHistory)
      )
    )
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(uploadSaga);
  sagaMiddleware.run(userUnlockSaga);
  sagaMiddleware.run(uiSaga);
  sagaMiddleware.run(desktopAppReviewSaga);
  sagaMiddleware.run(activeMeetingsSaga);
  sagaMiddleware.run(meetingPopupSaga);
  return { store, persistor };
}
